<template>
  <div class="home">
    <div class="page-title">
      <vue-recaptcha
        style="display: none"
        size="invisible"
        ref="recaptcha"
        sitekey="6Lf0FfIhAAAAAHPDqh6Qh-ZhawvF2xWIW4nvcFfr"
        @verify="verifyRecaptchaMethod"
        @render="renderMethod"
      ></vue-recaptcha>
      <h1>Děkujeme Vám za Váš zájem!</h1>
      <p>
        Vaši zprávu jsme v pořádku obdrželi a brzy Vás budeme kontaktovat. Pokud
        máte jakýkoli dotaz, rádi jej zodpovíme na e-mailu
        <a href="mailto:vedova@tmesolutions.cz">vevodova@tmesolutions.cz.</a>
      </p>
    </div>
  </div>
</template>

<script>
import { VueRecaptcha } from "vue-recaptcha";

export default {
  name: "Home",
  components: { VueRecaptcha },
  data() {
    return {
      email: null,
      recaptchaToken: null,
    };
  },
  mounted() {
    this.email = this.$route.params.id;
  },
  methods: {
    renderMethod() {
      this.$refs.recaptcha.execute();
    },
    async verifyRecaptchaMethod(value) {
      this.recaptchaToken = value;
      await this.sendEmail();
    },
    async sendEmail() {
      const email = this.email;
      if (!email) {
        return;
      }
      await fetch(
        "https://tmemails.tmesolutions.cz/api/mails/dotaznik-tmesolutions-cz/send",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "App-Token": "b061a921d9084c11b25aadbe31f1a2c2",
          },
          body: `{ "mail":{"form": '{"Email uživatele:": "${email}"}', "reCaptchaToken": "${this.recaptchaToken}"}}`,
        }
      );
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: TitiliumRegular;
  src: url("../../public/fonts/TitilliumWeb-Regular.ttf");
}

.home {
  font-family: TitiliumRegular;
}

.page-title {
  position: absolute;
  max-width: 50rem;
  top: 50%;
  right: 50%;
  translate: 50% -50%;
  text-align: center;
  font-size: 1.5rem;
}
</style>
